import styled from 'styled-components'

import theme from '../../../theme/content'

export const List = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
`

export const Item = styled.li`
    border-bottom: 1px solid ${theme.colors.alto};
    cursor: pointer;
    display: flex;
    height: auto;
    justify-content: space-between;
    margin-bottom: 0;
    overflow: hidden;
    width: 100%;
`

export const ItemContent = styled.div`
    width: 95%;
`

export const Question = styled.h5`
    font-family: ${theme.fonts.primary};
    font-size: 22px;
    font-weight: 400;
    margin: 0.5em 0;
    padding-right: 1em;
    transition: all 0.4s;

    &.opened {
        color: ${theme.colors.crusta};
    }
`

export const Answer = styled.p`
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    margin-bottom: 0;
    padding-right: 1em;
`

export const MaskAnswer = styled.div`
    height: 0;
    overflow: hidden;
    transition: height 0.4s;

    &.opened {
        height: 100px;
        padding-bottom: 5px;
    }
`

export const ToggleBTn = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    height: 48px;
    font-size: 24px;
    outline: none;
    padding: 0;
    transition: all 0.4s;
    width: 5%;

    &.opened {
        color: ${theme.colors.crusta};
    }
`