import React, {Component} from 'react'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import { Line } from 'rc-progress'


import {
  	Heading, Paragraph, Flex
} from 'reakit'

import {
	Image,
	Card,
} from '..'

import theme from '../../../theme/content'
import {HorizontalScroll} from './CategoryScrollStyles'


class CategoryScroll extends Component {
	constructor(props) {
		super(props)

		this.state = {
			detailsHeight: 0
		}
	}

	render() {
		const {categories, selected} = this.props
		return(
			<div>
				<Flex row style={{ margin: 0, padding: 0, paddingLeft: 20, paddingTop: 10}}>
					{selected ?
						<Flex row onClick={()=>this.props.onClickCategory()} style={{cursor: 'pointer'}}>
							<Paragraph style={{fontSize: 12, margin: 0, padding: 0, fontWeight: 800, lineHeight: '18px', marginRight: 5}}>Clear Category Filter</Paragraph>
							<Image
								src={"https://storage.googleapis.com/resources.flourishchange.com/MobileApps/Close_red.svg"}
								height="15px"
								width="auto"
							/>
						</Flex>
						:
						<Flex row style={{height: 35}}>
							<Paragraph style={{fontSize: 12, margin: 0, padding: 0, fontWeight: 800, lineHeight: '25px', marginRight: 5}}>Filter by category:</Paragraph>
						</Flex>
					}
				</Flex>
				<HorizontalScroll row>
					{categories.map(item => (
						<Flex onClick={() => this.props.onClickCategory(item.category_id)} column style={{margin: "0px 5px", minWidth: 125, textAlign: 'center'}}>
							<div>
								<Image
									src={item.image_url}
									height={'75px'}
									width={'112.5px'}
									style={{margin: 0,
										border: selected === item.category_id ? `3px solid ${theme.colors.sunglow}` : `1px solid ${theme.colors.lightGray}`,
										borderRadius: 8, boxShadow: '4px 4px 4px -3px rgba(0,0,0,0.16)'}}
								/>
							</div>
							<Paragraph style={{fontWeight: selected === item.category_id ? 800 : 400}} lineHeight="1em" fontSize="12px">{item.title}</Paragraph>
						</Flex>))}
				</HorizontalScroll>
			</div>

		)
	}
}

CategoryScroll.propTypes = {
	categories: PropTypes.object,
	selected: PropTypes.number,
	onClickCategory: PropTypes.func
}

CategoryScroll.defaultProps = {
	categories: []
}

export default CategoryScroll
