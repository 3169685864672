import styled from 'styled-components'
import {Flex} from 'reakit'


import theme from '../../../theme/content'

export const HorizontalScroll = styled.div`
	display: flex;
	justify-content: row;
	overflow: hidden;
	overflow-x: scroll;
	padding: 1em;
	width: 100%;
	margin-top: 0px;
	padding-top: 0px;
	padding-right: 10px;
	padding-left: 8px;

	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
    }

    &::-webkit-scrollbar-track {
		background: #EEEEEE; 
		border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: ${theme.colors.crusta}; 
        transition: all 0.4s;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: ${theme.colors.salomie}; 
	}
	
	@media (max-width: ${theme.breakPoints.small}) {
		max-height: calc(100vh - 150px);
	}
`