import styled from 'styled-components'
import {Button as Btn, Overlay as Ovrly, Link as Lnk} from 'reakit'

import theme from '../../../theme/content'

export const Button = styled(Btn).attrs({
	disabled: props => (props.disabledBtn ? props.disabledBtn : ''),
})`
	background-color: ${props => (props.backgroundColor ? props.backgroundColor : theme.colors.sunglow)};
	border-radius: 50px;
	color: ${theme.colors.mineShaft};
	max-width: ${props => (props.width ? props.width : '75px')};
	width: 100%;

	&:hover {
		background-color: ${theme.colors.crusta};
	}

	&:disabled {
		background-color: rgb(247, 119, 47);
	}
`

export const Overlay = styled(Ovrly)`
  min-width: 40%
`
