import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { navigate } from '@reach/router'

import {
	InlineBlock,
	Button,
	Popover,
	Heading,
	Flex
} from 'reakit'

import {
	NavButton
} from '../../ui'

import {
	BodyContainer,
	BodyHeader,
	BodyContent,
	BodyScrollable,
	BodyFooter,
	Logout,
} from './bodyStyles'

import {
	logout,
} from '../../../factory'

import {
	cleanSession
} from '../../../util/auth'

import theme from '../../../theme/content'

class Body extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<BodyContainer {...this.props} >
				<BodyContent>
					<BodyScrollable style={{margin: 0}}>{this.props.children}</BodyScrollable>
				</BodyContent>
			</BodyContainer>
		)
	}
}

Body.propTypes = {
	children: PropTypes.any,
	onLogout: PropTypes.func,
	maxWidth: PropTypes.string,
	showDate: PropTypes.bool,
	title: PropTypes.string
}

const mapStateToProps = (state) => {
	const adminOrg = state.adminState.organization
	const authOrg = state.authState.adminOrg
	return {
		userRole: state.authState.role,
		is_signed: (adminOrg && adminOrg.is_signed) ||  (authOrg && authOrg.is_signed)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onLogout: () => {
			dispatch(logout())
			cleanSession()
			navigate('/login')
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Body)
