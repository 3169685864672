import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { navigate } from '@reach/router'

import {
	Heading,
	Flex,
	Paragraph,
} from 'reakit'

import {
	BodyContainer,
	BodyHeader,
	BodyContent,
	BodyScrollable,
} from './bodyStyles'

import {Sidebar} from '../'

import {
	logout,
} from '../../../factory'

import {
	cleanSession
} from '../../../util/auth'
import {incompleteAccount} from '../../../util'

import theme from '../../../theme/content'

class Body extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			showIncomplete: false
		}

		this.showIncomplete = this.showIncomplete.bind(this)
	}

	componentDidMount() {
		setTimeout(()=>this.setState({showIncomplete: true}), 1000)
	}

	showIncomplete(incomplete) {
		const location = typeof(window) !== 'undefined' ? window.location.pathname : ''

		return this.state.showIncomplete && !this.props.pending && incomplete.status && location !== incomplete.href.split('?')[0] && !location.includes('/register/')
	}

	render() {
		const incomplete = incompleteAccount(this.props)

		return (
			<BodyContainer {...this.props} >
				<BodyContent style={{position: 'relative', }}>
					<Sidebar/>
					<div>
						<BodyHeader bgColor={'white'}>
							<Heading as="h4" textAlign={'center'} width={'100%'}>{this.props.title}</Heading>
						</BodyHeader>
						{this.showIncomplete(incomplete) &&
						<BodyHeader onClick={()=>navigate(incomplete.href)}
							style={{minHeight: '50px', background: theme.colors.crusta, justifyContent: 'center', cursor: 'pointer', padding: "0.25em", paddingBottom: '0.1em', flexDirection: 'column'}}>
							<Heading as="h6" fontSize={14} textAlign={'center'} width={'100%'} margin={'auto'} lineHeight={'1em'}>Account not fully setup: <br/> {incomplete.message}</Heading>
							<Paragraph style={{fontSize: 12, color: '#e4e4e4'}}>(click to resolve)</Paragraph>
						</BodyHeader>
						}
					</div>
					<BodyScrollable style={{margin: 0, }}>
						<Flex column>
							{this.props.children}
						</Flex>
					</BodyScrollable>
				</BodyContent>
			</BodyContainer>
		)
	}
}

Body.propTypes = {
	children: PropTypes.any,
	onLogout: PropTypes.func,
	maxWidth: PropTypes.string,
	showDate: PropTypes.bool,
	title: PropTypes.string,
	incomplete: PropTypes.object,
	pending: PropTypes.bool
}

const mapStateToProps = (state) => {
	const adminOrg = state.adminState.organization
	const authOrg = state.authState.adminOrg
	return {
		userRole: state.authState.role,
		organization: state.userState.organization,
		monthly_min_donation_limit: state.userState.monthly_min_donation_limit,
		accountList: state.userState.accountList,
		donor_type: state.userState.donor_type,
		is_signed: (adminOrg && adminOrg.is_signed) ||  (authOrg && authOrg.is_signed),
		accountsRecieved: state.userState.accountsRecieved,
		pending: state.authState.pending || state.userState.pending || state.userState.profile_pending
				|| state.discoverState.pending || state.adminState.pending
				|| state.accountState.pending || state.bankState.pending
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onLogout: () => {
			dispatch(logout())
			cleanSession()
			navigate('/login')
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Body)
