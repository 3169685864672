import styled from 'styled-components'
import {Box, Button, Card as Crd} from 'reakit'


import theme from '../../../theme/content'

export const BodyContainer = styled.main`
	background-color: ${props => props.bgColor ? props.bgColor : 'white'};
	background-image: ${props => props.bg && props.bg.image ? 'url(' + props.bg.image + ')' : 'none'};
	background-repeat: ${props => props.bg && props.bg.repeat ? props.bg.repeat : 'no-repeat'};
	background-size: ${props => props.bg && props.bg.size ? props.bg.size : 'auto'};
	background-position: ${props => props.bg && props.bg.position ? props.bg.position : 'center'};
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: -webkit-fill-available;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding-top: 25px;
	color: black;
	position: fixed;

	@media (max-width: ${theme.breakPoints.small}) {
		padding-top: 0;
	}
	`

export const BodyHeader = styled(Box)`
	align-items: center;
	background-color: ${theme.colors.sunglow};
	display: flex;
	justify-content: space-between;
	padding: 1em 1em 0.25em 1em;
	min-height: 70px;
	width: 100%;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 20px 35px 0 rgba(74,74,74,0.1);
	position: relative;
`

export const BodyFooter = styled(Crd)`
	background-color: white;
	display: flex;
	justify-content: space-between;
	padding-left: 0.2em 1em;
	width: 100%;
	background: white;
	justify-content: center;
	align-items: center;
	box-shadow: 0px -20px 35px 0 rgba(74,74,74,0.1);
	border-top: 2px solid black;
	height: 90px;
`

export const Logout = styled(Button)`
	background-color: transparent;
	color: ${theme.colors.mineShaft};
	font-family: ${theme.fonts.primary};
	font-size: 14px;
	width: 100%;
	text-align: left;
`

export const BodyContent = styled(Box)`
	align-items: start;
	max-height: 100vh;
	min-width: 450px;
	width: 30%;
	overflow: hidden;
	background:  ${props => props.bgColor ? props.bgColor : 'white'};
	margin-bottom: 25px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

	@media (max-width: ${theme.breakPoints.small}) {
		min-width: 100%;
		width: 100%;
		margin-bottom: 0px;
		height: 100vh;
	}
`
export const BodyScrollable = styled.div`
	max-height: calc(80vh - 110px);
	min-height: calc(80vh - 110px);
	overflow: hidden;
	overflow-y: scroll;
	padding: 0em 0em 5em 0em;
	width: 100%;
	align-items: center;

	&::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
		background: #EEEEEE; 
		border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: ${theme.colors.crusta}; 
        transition: all 0.4s;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: ${theme.colors.salomie}; 
	}
	
	@media (max-width: ${theme.breakPoints.small}) {
		max-height: calc(100vh - 70px);
		min-height: calc(100vh - 70px);
	}
`
