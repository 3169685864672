import React from 'react'
import PropTypes from 'prop-types'

import {
	Container,
} from '../../layouts'

import {
	CheckBox,
	Input,
	Button,
	Form
} from '../'

import {
	FormContainer,
} from './oneTimeDonateStyles'

import theme from '../../../theme/content'
import { Flex, Heading,
	Paragraph, } from 'reakit'

const data = {title: "You make a difference",
	subtitle: "Your one time donation",
	values: [
		{
			name: "$1",
			value: 1,
		},
		{
			name: "$5",
			value: 5,
		},
		{
			name: "$10",
			value: 10,
		},
		{
			name: "$25",
			value: 25,
		},
		{
			name: "$50",
			value: 50,
		}]
}

class OneTimeDonate extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			customActive: false,
			selected: 5,
			customValue: null
		}

		this.selectDonateAmount = this.selectDonateAmount.bind(this)
		this.setCustomDonateAmount = this.setCustomDonateAmount.bind(this)
		this.validation = this.validation.bind(this)
		this.submitForm = this.submitForm.bind(this)
	}

	selectDonateAmount(e) {
		if (Number(e.target.value)) {
			const value = Number(e.target.value)

			if (value > 0 || value === -1) {
				this.setState({
					customActive: value === -1 ? true : false,
					selected: value,
				})
			}
		}
	}

	setCustomDonateAmount(e) {
		if (Number(e.target.value) || e.target.value === '') {
			const value = Number(e.target.value)

			if (value > 0 || value === -1) {
				this.setState({
					customValue: value,
				})
			}
		}
	}

	validation() {
		if (!this.state.selected) {
			this.setState({
				error: 'Select a donation amount.',
			})

			return false
		}

		this.setState({
			error: '',
			valid: true,
		})

		return true
	}

	submitForm(e) {
		e.preventDefault()

		const amount = this.state.customActive ? this.state.customValue : this.state.selected

		if (this.validation()) {
			const { key, orgId, projectId, token } = this.state

			// this.props.processOneTimeDonation(token, key, amount, projectId, orgId, () => {
			// 	if (!this.state.error && !this.props.error) {
			// 		this.nextPosition()
			// 	}
			// })

			alert('submit')
		}
	}

	render() {
		return (
			<FormContainer>
				<Container>
					<Flex column>
						<Heading as="h1" fontSize={'33px'} marginLeft={'0'} lineHeight={'1em'}>How much would you like to give?</Heading>
						{ typeof(window) !== 'undefined' && window.innerWidth > theme.breakPoints.small &&
							<Paragraph fontSize={'21px'} marginLeft={'0'}>Every penny makes a difference</Paragraph>
						}
						<Flex row style={{width: '100%'}}>
							{data.values.map((item, i) =>
								<CheckBox style={{flex: 1}} name={'donation'} key={i} label={item.name} defaultSelected={item.value === this.state.selected} value={item.value} onChange={this.selectDonateAmount} />
							)}
						</Flex>
						<Flex row style={{width: '100%', marginTop: 20, height: 75, marginBottom: 0}}>
							<CheckBox style={{marginRight: 25, height: 75, maxWidth: '50%'}} name={'donation'} label={'custom'} defaultSelected={this.state.selected === -1} value={-1} onChange={this.selectDonateAmount} />
							{this.state.customActive &&
								<div style={{height: '2em', width: 150}}>
									<Input
										label={'$ Amount'}
										name={'customValue'}
										field={'default'}
										placeholder={'$18'}
										type={'text'}
										maxWidth={'400px'}
										value={this.state.customValue}
										defaultValue={''}
										setValues={this.setCustomDonateAmount}
									/>
								</div>
							}
						</Flex>
						{this.props.errors && <Paragraph color={theme.colors.red}>{this.props.errors}</Paragraph>}
						<Button maxWidth={'180px'} onClick={this.submitForm}>
						Submit Donation
						</Button>
					</Flex>
				</Container>
			</FormContainer>
		)
	}
}

OneTimeDonate.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	values: PropTypes.object,
	onChange: PropTypes.func,
	onCustomChange: PropTypes.func,
	isCustom: PropTypes.bool,
	selectedOption: PropTypes.number,
	submitBtn: PropTypes.string,
	errors: PropTypes.any,
	valid: PropTypes.bool,
	submit: PropTypes.func,
	loading: PropTypes.bool,
	redirect: PropTypes.string
}

export default OneTimeDonate
