import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {
  	Heading, Flex, Paragraph
} from 'reakit'

import {AccountCard} from './AccountItemStyles'


import theme from '../../../theme/content'
import CheckBox from '../CheckBox'
import Button from '../Button'
import { Modal } from '../../layouts'
import {Loader} from '../'
import { navigate } from '@reach/router'

class AccountItem extends Component {
	constructor(props) {
		super(props)

		this.state = {
		}

		this.renderRelinkModal = this.renderRelinkModal.bind(this)
	}

	renderRelinkModal() {
		const {account} = this.props
		if (account.is_linked) {
			return
		}
		navigate('/admin/profile/relink/')
	}

	render() {
		const {account, pending} = this.props

		return(
			<Flex style={{alignItems: 'center', marginBottom: 10, justifyContent: 'space-between'}}>
				{account.is_trackable ?
					<CheckBox
						style={{display: 'flex'}}
						defaultSelected={account.is_tracked_for_roundups}
						onChange={this.props.onCheck}
						name={account.name}
						value={account.account_id}
					/> :
					<div style={{width: 50, height: 25, background: 'transperent'}}/>
				}
				<AccountCard
					onClick={()=>this.renderRelinkModal()}
					row style={{...this.props.style}} linked={account.is_linked}>
					<Heading as="h4" fontSize="16px" lineHeight="25px" marginBottom={0}>{account.name.substring(0, 22)} ...{account.mask}</Heading>
					{!account.is_linked &&
						<Paragraph style={{position: 'absolute', bottom: -5, right: 0, left: 0, fontSize: 10, textAlign: 'center', color: theme.colors.crusta}}>Tap To Re-Link</Paragraph>
					}
				</AccountCard>
				<Modal
					button={{
						backgroundColor: 'transparent',
						text: 'X  ',
						fontWeight: 'bold',
						color: theme.colors.crusta,
						marginTop: '5px',
						paddingLeft: 0,
						fontSize: 22,
						height: 'auto',
						textAlign: 'left'
					}}
					style={{padding: 0}}
					containterStyle={{width: '80%', maxWidth: 500}}
				>
					<div>
						<Heading as="h1" fontSize="22px">Are you sure?</Heading>
						<Paragraph>This bank account will be removed from your profile and you won't be able to track accounts or use it as a funding source.</Paragraph>
						{pending ?
							<Loader/> :
							<Button onClick={()=>{
								this.props.onDeleteAccount(account.account_id)
							}
							} style={{background: theme.colors.crusta, color: 'white', padding: '5px, 10px', maxWidth: 250}}>Yes, unlink my account</Button>}
					</div>
				</Modal>

			</Flex>
		)
	}
}

AccountItem.propTypes = {
	account: PropTypes.object,
	onTrackForRoundups: PropTypes.func,
	style: PropTypes.object,
	onDeleteAccount: PropTypes.func,
	pending: PropTypes.bool,
	onCheck: PropTypes.func
}

export default AccountItem
