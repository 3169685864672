import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {
  	Heading, Paragraph, Flex
} from 'reakit'

import {
	Image,
} from '..'

import theme from '../../../theme/content'
import currencyFormat from '../../../util/currencyFormat'

class OrganizationCard extends Component {
	constructor(props) {
		super(props)

		this.state = {
			detailsHeight: 0
		}

		this.renderTitle = this.renderTitle.bind(this)
		this.renderIcon = this.renderIcon.bind(this)
	}

	renderTitle(type) {
		switch(type) {
		case (0): return "Round Up Donation"
		case (1): return "Daily Donation"
		default: return "One Time Donation"
		}
	}

	renderIcon(type, status) {
		if (status === 1) {
			return (
				<Image
					src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Icons/PendingRoundUpIcon.png'}
					heigth={"40px"}
					width={'40px'}
					marginBottom={'0px'}
					marginRight={"10px"}
				/>
			)
		}
		let imgURL = ""

		switch(type) {
		case (0): {
			imgURL = 'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Icons/round_up_icon.png'
			break
		} case (1): {
			imgURL = 'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Icons/DailyDonationIcon.svg'
			break
		}
		default: {
			imgURL = 'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Icons/OneTimeDonationBadge.png'
			break
		}
		}

		return (
			<Image
				src={imgURL}
				heigth={"40px"}
				width={'40px'}
				marginBottom={'0px'}
				marginRight={"10px"}
			/>
		)
	}

	render() {
		const {item} = this.props
		return(
			<Flex row style={{...this.props.style, width: '100%'}}>
				<Flex row  style={{width: '90%', margin: "5px 10px", borderBottom: `1px solid ${theme.colors.lightGray}`, alignItem: 'center', paddingBottom: 10, justifyContent: 'space-between'}}>
					<Flex row>
						{this.renderIcon(item.type, item.status)}
						<div style={{display: 'flex', flexDirection: 'column'}}>
							<Heading as="h4" fontSize="16px" lineHeight="25px" marginBottom={0}>{this.renderTitle(item.type)}</Heading>
							<Paragraph fontSize="12px" marginTop={0} marginBottom={0} color={theme.colors.dustGray}>{item.date.split(' ')[0].replace(/-/g, "/")} {item.name && "- " + item.name} {item.project_name && "- " + item.project_name}</Paragraph>
							<Paragraph fontSize="12px" marginTop={0} color={theme.colors.dustGray} lineHeight={'12px'}>{item.organization_title}</Paragraph>
						</div>
					</Flex>
					<div style={{height: "100%", justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
						<Paragraph fontSize="18px" marginTop={0}>{currencyFormat(item.sum)}</Paragraph>
					</div>
				</Flex>
			</Flex>
		)
	}
}

OrganizationCard.propTypes = {
	item: PropTypes.object,
	primary: PropTypes.bool,
	style: PropTypes.object,
	onSelectOrganization: PropTypes.func
}

export default OrganizationCard
