import React from 'react'
import PropTypes from 'prop-types'

import {
	Button,
	Input,
	Loader,
} from '../../ui'

import {
	Row,
	ErrorMessage,
} from './formStyles'

class Form extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			focus: false,
		}

		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.renderButton = this.renderButton.bind(this)
	}

	handleChange(e) {
		this.props.setValues(e)
	}

	handleSubmit(event) {
		event.preventDefault()

		if (this.props.validate ? this.props.validate() : true) {
			this.props.submitForm(event)
		}
	}

	renderButton() {
		if (this.props.logging && !this.props.error) {
			return <Loader />
		} else if (this.props.submitBtnText) {
			return (
				<Button as="button" disabled={this.props.disabled ? this.props.disabled : false}>
					{this.props.submitBtnText}
				</Button>
			)
		}
		return null
	}

	render() {
		return (
			<form onSubmit={this.handleSubmit} style={{...this.props.style}}>
				{this.props.error && <ErrorMessage>{this.props.error}</ErrorMessage>}
				{this.props.fields && this.props.fields.map((fields, index) => {
					return (
						<Row key={index}>
							{fields &&
								fields.map((field, i) => {
									return (<Input
										key={i}
										{...field}
										value={this.props.values && this.props.values[field.name] ? (typeof(this.props.values[field.name]) === 'object' ? this.props.values[field.name].id : this.props.values[field.name]) : field.value}
										defaultValue={this.props.defaultValues && this.props.defaultValues[field.name] ? (typeof(this.props.defaultValues[field.name]) === 'object' ? this.props.defaultValues[field.name].id : this.props.defaultValues[field.name]) : field.defaultValue}
										options={this.props.selectOptions && this.props.selectOptions[field.name] ? this.props.selectOptions[field.name] : field.options}
										setValues={this.handleChange}
										maxWidth={`calc(${field.maxWidth} - ${1 - 1 / fields.length}em)`}
									/>)
								})}
						</Row>
					)
				})}
				{this.renderButton()}
			</form>
		)
	}
}

Form.propTypes = {
	fields: PropTypes.array,
	selectOptions: PropTypes.any,
	defaultValues: PropTypes.object,
	setValues: PropTypes.func,
	submitBtnText: PropTypes.string,
	submitForm: PropTypes.func,
	error: PropTypes.string,
	validate: PropTypes.func,
	disabled: PropTypes.bool,
	logging: PropTypes.bool,
	values: PropTypes.object,
	style: PropTypes.object
}

export default Form
