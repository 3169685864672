import styled from 'styled-components'
import { Card as Crd} from 'reakit'

const Card = styled(Crd)`
	border-radius: 5px;
	padding: 1em;
	padding-bottom: 0em;
	max-width: 100%;
	width: 100%;
  	margin-bottom: 1em;
`

export default Card
