import React from 'react'
import MediaQuery from 'react-responsive'

import {
	Bar,
	BarContainer,
	Logo,
	Menu,
	MenuOption,
	MenuLink,
} from './topBarStyles'

import {Container} from '../../layouts'

import theme from '../../../theme/content'

class TopBar extends React.Component {
	constructor(props) {
		super(props)

		this.linking = this.linking.bind(this)
	}

	linking(e) {
		e.preventDefault()

		const link = e.target.getAttribute('href')
		window.location.href = link
	}

	render() {
		return (
			<Bar>
				<Container>
					<BarContainer>
						<Logo />
						<MediaQuery minWidth={theme.layoutsContent.webTopBar.breakPoints.small}>
							<Menu>
								{theme.layoutsContent.webTopBar.mainMenu &&
									theme.layoutsContent.webTopBar.mainMenu.length &&
									theme.layoutsContent.webTopBar.mainMenu.map((option, i) => (
										<MenuOption key={i} highlight={option.highlight}>
											<MenuLink
												onClick={this.linking}
												href={option.link}
											>
												{option.name}
											</MenuLink>
										</MenuOption>
									))}
							</Menu>
						</MediaQuery>
					</BarContainer>
				</Container>
			</Bar>
		)
	}
}

export default TopBar
