import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {Image, Block } from 'reakit'

import {SidebarContainer, Option, SubOption, Menu, BurgerButton} from './sidebarStyles'

import {logout} from '../../../factory'

import theme from '../../../theme/content'

class Sidebar extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			show: false
		}

		this.content = theme.adminSidebar
	}

	render() {
		const {show} = this.state
		if (!show) {
			return(
				<BurgerButton
					src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Website/userDashboard/MenuButton.svg'}
					onClick={()=>this.setState({show: true})}
				/>
			)
		}
		return (
			<SidebarContainer  onClick={()=>this.setState({show: false})}>
				<Menu width={this.state.show ? "200px" : "0px"} onClick={()=>this.setState({show: true})}>
					<Block>
						<Block style={{alignItems: 'flex-end', display: 'flex', width: '100%', }}>
							<Image
								src={"https://storage.googleapis.com/resources.flourishchange.com/Marketing/Website/userDashboard/Close_white.svg"}
								height={18}
								width={'auto'}
								marginLeft={'auto'}
								marginTop={10}
								marginRight={10}
								cursor={'pointer'}
								onClick={()=>this.setState({show: false})}
							/>
						</Block>
						{this.content && this.content.options.map((item, index) => (
							<div key={index}>
								<Option as={'a'} href={item.link} key={index}>
									<Image
										src={item.imageUrl}
										marginBottom={'0em'}
										marginRight={'10px'}
										maxWidth={'100%'}
										height={"25px"}
										width={'auto'}
									/>
									{item.text}
								</Option>
								{item.subItems && item.subItems.map((subItem, k) => {
									if (typeof window !== 'undefined' && window.location.href.includes(item.link)) {
										return (
											<SubOption as={'a'} href={`${item.link}${subItem.link}`} key={k}>
												<i className={'fas fa-circle'} />
												{subItem.text}
											</SubOption>
										)
									}
									return null
								})

								}

							</div>
						))}
					</Block>
					<Block style={{position: 'absolute', bottom: 10, left: 10}}>
						<Option as={'a'} style={{width: '100%', marginLeft: 0}} onClick={()=>this.props.logout()} >
							<Image
								src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Website/userDashboard/Close_white.svg'}
								marginBottom={'0em'}
								marginRight={'10px'}
								maxWidth={'100%'}
								height={"15px"}
								width={'auto'}
							/>
							Log Out
						</Option>
						<Image src={theme.images.logo} width={160} style={{margin: 'auto', marginLeft: 10}}/>
					</Block>
				</Menu>
			</SidebarContainer>
		)
	}
}

Sidebar.propTypes = {
	content: PropTypes.object,
	logout: PropTypes.func
}

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => dispatch(logout())
	}
}

export default connect(
	null,
	mapDispatchToProps,
)(Sidebar)
