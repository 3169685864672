import styled from 'styled-components'
import {Flex, Button, Image} from 'reakit'

import theme from '../../../theme/content'

export const BurgerButton = styled(Image)`
	height:18px;
	width:auto;
	marginLeft:auto;
	marginTop:5px;
	marginRight:5px;
	cursor: pointer;
	position: absolute;
	z-index: 4;
	top: 30px;
	left: 30px;
`

export const SidebarContainer = styled(Flex)`
	position: absolute;
	align-items: center;
	background-color: ${theme.colors.yellow};
	display: flex;
	height: 100vh;
	justify-content: space-between;
	max-height: 100%;
	width: 100%;
	height: 100%; 
	background: rgba(0, 0, 0, .25); 
	z-index: 5;
	transition: all 0.25s;
`

export const fillContainer = styled.div`
	width: calc(100% - 200px);
`

export const Menu = styled.div`
	background: ${theme.colors.sunglow};
	width: ${props => props.width}; 
	height: 100%;
	position: relative;,
	padding-top:25px;
	transition: all 0.25s;
`

export const Option = styled(Button)`
	justify-content: left;
	background-color: transparent;
	border-radius: 3.5em;
	color: ${theme.colors.white};
	font-family: ${theme.fonts.primary};
	font-size: 14px;
	font-weight: 400;
	height: 3.5em;
	margin-bottom: 1em;
	text-transform: uppercase;
	width: 80%;
	margin-left: 5px;
	position: relative;

	i {
		font-size: 18px;
		margin-right: 0.5em !important;
	}

	&:hover {
		color: ${theme.colors.white};
	}
`

export const SubOption = styled(Button)`
	justify-content: left;
	background-color: transparent;
	border-radius: 3.5em;
	color: ${theme.colors.white};
	font-family: ${theme.fonts.primary};
	font-size: 14px;
	font-weight: 400;
	height: 2em;
	margin-left: 20px;
	margin-bottom: 0.5em;
	text-transform: uppercase;
	width: 90%;

	i {
		font-size: 18px;
		margin-right: 0.5em !important;
	}

	&:hover {
		color: ${theme.colors.white};
	}
`