import React from 'react'
import PropTypes from 'prop-types'

import {Image, Link} from '../'
import theme from '../../../theme/content'
import { Paragraph, Flex } from 'reakit'

class NavButton extends React.Component {
	constructor(props) {
		super(props)
		this.state = { screenWidth: null }
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
	  }

	componentDidMount() {
		window.addEventListener("resize", this.updateWindowDimensions())
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowDimensions)
	}

	updateWindowDimensions() {
	   this.setState({ screenWidth: typeof(window) !== 'undefined' ? window.innerWidth : null})
	}

	render() {
		const {screenWidth} = this.state
		let imgURL = this.props.imageUrl
		if (typeof(window) !== 'undefined' && window.location.pathname === this.props.link) {
			imgURL = imgURL.split('.svg')[0] + '_black.svg'
		}
		return(
			<Link href={this.props.link} style={{color: 'black',
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				textAlign: 'center',
				alignItems: 'center', paddingBottom: 10}}>
				<Flex column style={{alignItems: 'center', justifyContent: 'center'}}>
					<div style={{minHeight: 25}}>
						<Image
							src={imgURL}
							marginBottom={'0em'}
							marginRight={'auto'}
							marginLeft={'auto'}
							maxWidth={'100%'}
							height={"auto"}
							width={'auto'}
						/>
					</div>
					{parseFloat(screenWidth) > parseFloat(theme.breakPoints.small) &&
						<Paragraph width="100%"color={'black'} fontSize={12} lineHeight="1em">{this.props.title}</Paragraph>
					}
				</Flex>
			</Link>
		)
	}
}

NavButton.propTypes = {
	title: PropTypes.string,
	imageUrl: PropTypes.string,
	link: PropTypes.string
}

export default NavButton

