import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {
  	Heading, Paragraph, Flex
} from 'reakit'

import {
	Image,
} from '..'

import theme from '../../../theme/content'

class OrganizationCard extends Component {
	constructor(props) {
		super(props)

		this.state = {
			detailsHeight: 0
		}

		this.renderTitle = this.renderTitle.bind(this)
	}

	renderTitle() {
		const {organization } = this.props
		if (organization.title.length > 23) {
			return organization.title.substring(0, 18).trim() + '...'
		}
		return organization.title
	}

	// in your component
	addDefaultSrc(ev) {
		ev.target.src = 'https://flourishfiles.blob.core.windows.net/files/Marketing/Patterns/LogoPatternWebSmall.jpg'
	}

	render() {
		const {organization, primary} = this.props
		return(
			<Flex
				onClick={() => this.props.onSelectOrganization(organization)} column style={{maxWidth: primary ? "85%" : '50%', width: primary ? "auto" : '150px', margin: 'auto', marginBottom: '10px'}} {...this.props.style}>
				<Image
					onError={this.addDefaultSrc}
					src={organization.image_url || 'https://flourishfiles.blob.core.windows.net/files/Marketing/Patterns/LogoPatternWebSmall.jpg'}
					height={primary ? "auto" : '100px'}
					width={'100%'}
					style={{margin: 0, border: `1px solid ${theme.colors.lightGray}`, borderRadius: 8, boxShadow: '4px 4px 4px -3px rgba(0,0,0,0.16)'}}
				/>
				{primary ?
					<Heading as="h1" fontSize="18px">{this.renderTitle()}</Heading>
					:
					<Paragraph fontSize="12px">{this.renderTitle()}</Paragraph>
				}
			</Flex>
		)
	}
}

OrganizationCard.propTypes = {
	organization: PropTypes.object,
	primary: PropTypes.bool,
	style: PropTypes.object,
	onSelectOrganization: PropTypes.func
}

export default OrganizationCard
