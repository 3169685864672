import React, {Component} from 'react'
import {StripeProvider} from 'react-stripe-elements'
import PropTypes from 'prop-types'
import {STRIPEKEY} from '../../../factory/types'
import MyStoreCheckout from './Checkout'
import { Overlay, Backdrop } from "reakit"


class Stripe extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<StripeProvider apiKey={STRIPEKEY}>
				<MyStoreCheckout {...this.props}/>
			</StripeProvider>
  	)
	}
}

Stripe.propTypes = {
	paid: PropTypes.bool
}
export default Stripe
