import React from 'react'
import PropTypes from 'prop-types'

import {
	Flex,
	Paragraph,
} from 'reakit'

import {
	Button,
	Loader,
} from '../'

import  {
	FormContainer,
	SearchField,
	SearchIcon,
	SearchInput,
	ListField,
	ListItem,
} from './organizationsFormStyles'

import theme from '../../../theme/content'

class OrganizationsForm extends React.Component {
	constructor(props) {
		super(props)

		this.title = this.props.title

		this.selectOption = this.selectOption.bind(this)
	}

	selectOption(org) {
		this.props.onSelectOrg(org)
	}

	render() {
		return (
			<FormContainer>
				<Flex row>
					<Flex column width="100%">
						{(this.props.errors && this.props.errors !== 'null') && <Paragraph color={theme.colors.red}>{ this.props.errors }</Paragraph>}
						<SearchField>
							<SearchIcon className={'fas fa-search'} />
							<SearchInput onChange={this.props.onChangeSearch} />
						</SearchField>
					</Flex>
				</Flex>
				<Flex row marginBottom="2em">
					<Flex column width="100%">
						{(this.props.matchingOrgs && this.props.matchingOrgs.length > 0) ?
							<ListField>
								{this.props.matchingOrgs.map((item, i) =>
									<ListItem key={i} className={(this.props.selectedOrg && this.props.selectedOrg.id === item.id) ? 'selected' : ''} onClick={this.selectOption.bind(this, item)}>{item.title}</ListItem>
								)}
							</ListField> :
							(this.props.orgs && this.props.orgs.length > 0) ?
								<ListField>
									{this.props.orgs && this.props.orgs.map((item, i) =>
										<ListItem key={i} className={(this.props.selectedOrg && this.props.selectedOrg.id === item.id) ? 'selected' : ''} onClick={this.selectOption.bind(this, item)}>{item.title}</ListItem>
									)}
								</ListField> :
								<Loader />
						}
					</Flex>
				</Flex>
			</FormContainer>
		)
	}
}

OrganizationsForm.propTypes = {
	back: PropTypes.func,
	skip: PropTypes.func,
	title: PropTypes.string,
	orgs: PropTypes.array,
	matchingOrgs: PropTypes.array,
	selectedOrg: PropTypes.object,
	searchOrgs: PropTypes.func,
	onSelectOrg: PropTypes.func,
	next: PropTypes.func,
	errors: PropTypes.string,
	onChangeSearch: PropTypes.func,
}

export default OrganizationsForm
