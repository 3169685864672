import styled from 'styled-components'


import {Flex} from 'reakit'

export const DetailsDiv = styled(Flex)`
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
    height: ${props => props.height};
    overflow: hidden;
    flex-direction: column;
    margin-top: 10px;
`
