import styled from 'styled-components'
import { Flex } from 'reakit'

import theme from '../../../theme/content'

export const AccountCard = styled(Flex)`
position: relative;
    width: 80%;
    background: ${theme.colors.wildSand}; 
    padding: 10px 5px;
    border: 2px solid ${props => props.linked ? 'transperent' : theme.colors.crusta};
    border-radius: 5px;
`
