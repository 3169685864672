// CheckoutForm.js
import React from 'react'
import PropTypes from 'prop-types'
import {injectStripe, CardElement} from 'react-stripe-elements'
import { Button, Loader } from '../'

import {Paragraph, Flex} from 'reakit'
import { connect } from 'react-redux'

import {linkCC} from '../../../factory'

class CheckoutForm extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
		}
	}


  handleSubmit = () => {
	  // We don't want to let default form submission happen here, which would refresh the page.

  	const {stripe, user, accessToken, redirect} = this.props

  	const data = {stripe, user, token: accessToken, redirect}
  	this.props.linkCC(data)
  	return true

  	// However, this line of code will do the same thing:
  	//
  	// this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});

  	// You can also use createSource to create Sources. See our Sources
  	// documentation for more: https://stripe.com/docs/stripe-js/reference#stripe-create-source
  	//
  	// this.props.stripe.createSource({type: 'card', owner: {
  	//   name: 'Jenny Rosen'
  	// }});
  };

  handleCheck(e) {
  	this.setState({[e.target.name]: e.target.checked})
  }

  render() {
  	return (
  		<div>
  			<CardElement style={{base: {fontSize: '18px'}}} />
  			{this.props.error && <Paragraph style={{fontSize: '12px', marginTop: 10, color: 'red'}}>{this.props.error}</Paragraph>}
  			<Flex row alignItems="center" justifyContent="left" marginTop={25}>
  				{this.props.pending ?	<Loader/> : <Button onClick={() => this.handleSubmit()} type="submit">Confirm</Button>}
  			</Flex>
  		</div>
  	)
  }
}

CheckoutForm.propTypes = {
	stripe: PropTypes.object,
	organization: PropTypes.object,
	pending: PropTypes.bool,
	user: PropTypes.object,
	accessToken: PropTypes.string,
	linkCC: PropTypes.func,
	redirect: PropTypes.string,
	error: PropTypes.string
}

const mapStateToProps = (state) => {
	return {
		user: state.authState.user,
		accessToken: state.authState.accessToken,
		pending: state.bankState.pending,
		error: state.bankState.error
	}
}

const mapDispatchToProps = (dispatch) => {
	return{
		linkCC: (data) => dispatch(linkCC(data))
	}
}

CheckoutForm = connect(
	mapStateToProps,
	mapDispatchToProps,
)(CheckoutForm)

export default injectStripe(CheckoutForm)
