import styled from 'styled-components'
import {Flex as Flx} from 'reakit'

import theme from '../../../theme/content'

export const Flex = styled(Flx)`

    width: ${props => props.bigWidth ? props.bigWidth : 'auto'};
    flex-wrap: wrap;

    @media (max-width: ${theme.breakPoints.small}) {
        width: 100%;
        margin-bottom: 15px;
    }
`
