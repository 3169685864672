import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Line } from 'rc-progress'


import {
  	Heading, Paragraph, Flex
} from 'reakit'

import {
	Loader,
	Image,
	Card,
	Button,
	OneTimeDonate
} from '../'

import {DetailsDiv} from './ProjectCardStyles'

import theme from '../../../theme/content'
import currencyFormat from '../../../util/currencyFormat'
import ProjectsForm from '../ProjectsForm'
import { Modal } from '../../layouts'


class ProjectCard extends Component {
	constructor(props) {
		super(props)

		this.state = {
			detailsHeight: 0
		}
	}

	renderImage() {
		const {project} = this.props
		if (project.funding_progress_percentage < 25) {
			return "https://storage.googleapis.com/resources.flourishchange.com/MobileApps/ProjectStatus/New.png"
		} else if (project.funding_progress_percentage < 66) {
			return "https://storage.googleapis.com/resources.flourishchange.com/MobileApps/ProjectStatus/Onboarding3%402x.png"
		}
		return "https://storage.googleapis.com/resources.flourishchange.com/MobileApps/ProjectStatus/Funded.png"
	}

	renderText() {
		const {project} = this.props
		if (project.funding_progress_percentage < 25) {
			return "This project is getting off to a great start!"
		} else if (project.funding_progress_percentage < 66) {
			return "We are on our way to fully funding this project!"
		}
		return "We did it!"
	}

	render() {
		const {project} = this.props
		return(
			<Card style={{border: `1px solid ${theme.colors.lightGray}`, alignItems: 'center', paddingTop: 8, marginTop: 12, margin: "10px auto", maxWidth: "85%"}}>
				<Flex column alignItems={'center'}>
					<Image
						src={this.renderImage()}
						height={125}
						width={'auto'}
						margin={'auto'}
					/>
					<Heading fontSize={19} as="h1" margin="0" textAlign="center">{project.name}</Heading>
					<Paragraph fontSize={16} textAlign="center" marginBottom={12}>{this.renderText()}</Paragraph>
					<div style={{height: 2, background: theme.colors.lightGray, width: '100%', marginBottom: 12}}/>
					<Flex row style={{justifyContent: "space-between", width: '100%'}}>
						<Heading as="h1" fontSize={16}  marginBottom={12}>{currencyFormat(project.donates_goal)}</Heading>
						<Paragraph fontSize={16} marginBottom={12}>{project.funding_progress_percentage}% funded</Paragraph>
					</Flex>
					<Line percent={project.funding_progress_percentage} strokeWidth="4" strokeColor={theme.colors.sunglow} trailColor={'#FFEBB4'} trailWidth={2} />
					<div style={{height: 2, background: theme.colors.lightGray, width: '100%', marginBottom: 12, marginTop: 12}}/>
					<Modal
						style={{display: 'flex', alignItems: 'center', }}
						containterStyle={{display: 'flex', flexDirection: 'column'}}
						button={{text: 'Give a little more', width: '10em', margin: 'auto'}}
					>
						<OneTimeDonate/>
					</Modal>
					<DetailsDiv height={this.state.detailsHeight}>
						<Paragraph fontSize={16} marginBottom={12}>You have contributed {currencyFormat(project.own_donation_sum)}</Paragraph>
						<Paragraph fontSize={16} marginBottom={12}>This month's largest donation is: {currencyFormat(project.top_current_month_donation)}</Paragraph>
					</DetailsDiv>
					{this.state.detailsHeight !== "75px" ?
						<Paragraph onClick={()=>this.setState({detailsHeight: "75px"})} style={{cursor: 'pointer', marginTop: 12, }}>
                        More Details
						</Paragraph> :
						<Image
							src={"https://storage.googleapis.com/resources.flourishchange.com/MobileApps/Close.svg"}
							height={18}
							width={'auto'}
							style={{cursor: 'pointer'}}
							onClick={()=>this.setState({detailsHeight: 0})}
						/>
					}
				</Flex>
			</Card>
		)
	}
}

ProjectCard.propTypes = {
	project: PropTypes.object
}

export default ProjectCard
