import React from 'react'
import PropTypes from 'prop-types'

import {
	Flex,
	Paragraph,
} from 'reakit'

import  {
	SearchField,
	SearchIcon,
	SearchInput,
} from './IconInputStyles'

import theme from '../../../theme/content'

class IconInput extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Flex column style={this.props.style}>
				{(this.props.errors && this.props.errors !== 'null') && <Paragraph color={theme.colors.red}>{ this.props.errors }</Paragraph>}
				<SearchField>
					<SearchIcon className={this.props.icon} />
					<SearchInput onChange={this.props.onChangeSearch} />
				</SearchField>
			</Flex>
		)
	}
}

IconInput.propTypes = {
	icon: PropTypes.string,
	errors: PropTypes.string,
	onChangeSearch: PropTypes.func,
	style: PropTypes.object
}

export default IconInput