/* eslint-disable */
import React from "react"
import { Bar } from "@vx/shape"
import { Group } from "@vx/group"
import { withTooltip } from "@vx/tooltip"
import { scaleBand, scaleLinear } from "@vx/scale"
import { AxisLeft, AxisBottom } from "@vx/axis"

function round(value, precision) {
	var multiplier = Math.pow(10, precision || 0)
	return Math.round(value * multiplier) / multiplier
}

import theme from '../../../theme/content'

// accessors
const x = d => `${d.month}/${d.year}`
const y = d => +d.sum
const xLables = d => `${d.month}/${d.year}`
const id = d => `${d.month}_${d.year}_${d.sum}`


class Bars extends React.Component {
	constructor(props){
		super(props)
	}

	render() {
		const {
			showTooltip,
			hideTooltip,
			tooltipLeft,
			tooltipTop,
			tooltipData
		} = this.props

		const margin = {
			left: 50,
			right: 10,
			top: 10,
			bottom: 25
		}

		const width = window.innerWidth*.5 - 300;
		const height = 200;

		// bounds
		const xMax = width - margin.left - margin.right
		const yMax = height - margin.top - margin.bottom

		// scales
		const xScale = scaleBand({
			range: [0, xMax],
			domain: this.props.data.map(x),
			padding: 0.4
		})
		const yScale = scaleLinear({
			rangeRound: [yMax, 0],
			domain: [0, Math.max(...this.props.data.map(y))],
			nice: true
		})

		let tooltipTimeout
		return (
			<React.Fragment>
				<svg width={width} height={height}>
					<rect
						x={0}
						y={0}
						width={'90%'}
						height={height}
						margin={'0 auto'}
						fill={'white'}
					/>
					<Group top={margin.top} left={margin.left}>
						<AxisLeft
							scale={yScale}
							tickFormat={d => `$${d}`}
							stroke={theme.colors.mineShaft}
	        				tickStroke={'transparent'}
							hideAxisLine
							tickLabelProps = {() =>
								({ textAnchor: 'middle',
									fontFamily: theme.fonts.primary,
									fontSize: 10,
									fill: theme.colors.mineShaft })
							}
						/>
						<AxisBottom
							top={yMax}
							scale={xScale}
							tickLength={0}
							stroke={theme.colors.mineShaft}
	        				tickStroke={theme.colors.mineShaft}
							tickLabelProps = {() =>
								({ textAnchor: 'middle',
									fontFamily: theme.fonts.primary,
									fontSize: 10,
									fill: theme.colors.mineShaft })
							} />
						{this.props.data.map((d, i) => {
							const barHeight = yMax - yScale(y(d))
							const offset = 4
							return (
								<Group key={`bar-${x(d)}`}>
									<Bar
										width={xScale.bandwidth()}
										height={barHeight}
										x={xScale(x(d))}
										y={yMax - barHeight}
										fill={theme.colors.crusta}
										data={{ x: xLables(d), y: y(d), id: id(d) }}
										onClick={data => event => {
											window.location = window.location.origin + '/admin/project?projectId=' + data.id
										}}
										onMouseLeave={d => event =>
											(tooltipTimeout = setTimeout(() => {
												hideTooltip()
											}, 300))}
										onMouseEnter={d => event => {
											if (tooltipTimeout) clearTimeout(tooltipTimeout)
											showTooltip({
												tooltipData: d,
												tooltipTop: yMax - barHeight + margin.top,
												tooltipLeft:
	                        xScale(d.x) + xScale.bandwidth() + margin.left + offset
											})
										}}
									/>
								</Group>
							)
						})}
					</Group>
				</svg>
				{tooltipData && (
					<div
						style={{
							position: "absolute",
							top: tooltipTop,
							left: tooltipLeft,
							backgroundColor: "white",
							borderRadius: 2,
							padding: ".5rem",
							pointerEvents: "none",
							fontFamily: "arial",
							border: "1px solid #efefef",
							boxShadow: "0 2px 3px rgba(0,0,0,0.1)"
						}}
					>
						<div>
							<small>{tooltipData.x}</small><br/>
							<strong>${round(tooltipData.y, 0)}</strong>
						</div>
					</div>
				)}
			</React.Fragment>
		)
	}
}


const BarChart = withTooltip(Bars)

export default BarChart;
