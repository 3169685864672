import React from 'react'
import PropTypes from 'prop-types'

import {
	Backdrop,
	Portal,
	Block,
	Link
} from 'reakit'

import {Button, Overlay, } from './modalStyles'

const Modal = (props) =>(
	<Overlay.Container>
		{overlay => (
			<Block style={{...props.style}}>
				{props.link ? <Link as={Overlay.Show} {...overlay} {...props.button} style={{fontWeight: 900, fontFamily: 'Frank Ruhl Libre', fontSize: 18, color: 'black', cursor: 'pointer', ...props.link.style}} onClick={props.onOpen}>{props.link.text}</Link>
				 : <Button as={Overlay.Show} {...overlay} {...props.button} onClick={props.onOpen}>{props.button.text}</Button>}
				<Backdrop as={[Portal, Overlay.Hide]} {...overlay} />
				<Overlay style={{...props.containterStyle}} as={Portal} {...overlay}>
					<Button as={Overlay.Hide} {...overlay} style={{position: 'absolute', top: 5, right: 5, background: 'transparent'}}>X</Button>
					{props.children}
				</Overlay>
			</Block>
		)}
	</Overlay.Container>
)

Modal.propTypes = {
	children: PropTypes.any,
	containterStyle: PropTypes.object,
	button: PropTypes.object,
	onOpen: PropTypes.func,
	link: PropTypes.object,
	style: PropTypes.object
}

Modal.defaultProps = {
	onOpen: () => {}
}

export default Modal
